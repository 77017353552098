import { createStore } from 'vuex';
import { axios } from '../axios';

export default createStore({
  state: {
    snackbar: {
      show: false,
      message: '',
      color: 'success'
    },
    selectedOptionVista: 'alumno',
    curso: null,
    loadingCurso: false,
    moduls: null,
    loadingModuls: false,
    alumnos_total: [],
    tabla_alumnos_notas: []
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.message = payload.message;
      state.snackbar.color = payload.color || 'success';
    },
    clearSnackbar(state) {
      state.snackbar.show = false;
      state.snackbar.message = '';
    },
    SET_CURSO(state, payload) {
      state.curso = payload;
    },
    SET_LOADING_CURSO(state, isLoading) {
      state.loadingCurso = isLoading;
    },
    SET_MODULOS(state, payload) {
      state.moduls = payload;
    },
    SET_LOADING_MODULOS(state, isLoading) {
      state.loadingModuls = isLoading;
    },
    SET_SELECTED_OPTION_VISTA(state, payload) { // Nueva mutación para actualizar selectedOptionVista
      state.selectedOptionVista = payload;
    },
    SET_ALUMNOS_TOTAL(state, alumnos) {
      state.alumnos_total = alumnos;
    },
    SET_TABLA_ALUMNOS_NOTAS(state, tabla) {
      state.tabla_alumnos_notas = tabla;
    },
  },
  actions: {
    triggerSnackbar({ commit }, payload) {
      commit('setSnackbar', payload);
      setTimeout(() => {
        commit('clearSnackbar');
      }, 5000); // Timeout for snackbar
    },
    setSelectedOptionVista({ commit }, payload) { // Nueva acción para despachar la mutación de selectedOptionVista
      commit('SET_SELECTED_OPTION_VISTA', payload);
    },
    // =============== NUEVAS ACCIONES para curso y modulos ===============

    async fetchCurso({ commit }, cursoId) {
      commit('SET_LOADING_CURSO', true);
      try {
        const response = await axios.get(`curso/${cursoId}/`);
        commit('SET_CURSO', response.data);
      } catch (error) {
        console.error(error);
        // Opcional: dispara snackbar de error
        commit('setSnackbar', {
          message: 'Error cargando el curso',
          color: 'error',
        });
      } finally {
        commit('SET_LOADING_CURSO', false);
      }
    },

    async fetchModuls({ commit, state }, cursoId) {
      // Puedes reutilizar el `selectedOptionVista` del estado o recibir un 2º parámetro
      const vista = state.selectedOptionVista || 'alumno';
      commit('SET_LOADING_MODULOS', true);
      try {
        const response = await axios.get(`curso_i/${cursoId}/${vista}/`);
        commit('SET_MODULOS', response.data);
      } catch (error) {
        console.error(error);
        commit('setSnackbar', {
          message: 'Error cargando módulos',
          color: 'error',
        });
      } finally {
        commit('SET_LOADING_MODULOS', false);
      }
    },

    // =============== ACCIÓN PARA OBTENER ALUMNOS Y NOTAS ===============

    async fetchAlumnosTotal({ commit }, cursoId) {
      try {
        const response = await axios.get(`curso/${cursoId}/alumnos_total/`);
        const alumnos = response.data;

        // Obtener todos los títulos de los cuestionarios únicos
        const cuestionariosSet = new Set(alumnos.map(a => a.cuestionario_titulo));
        const totalCuestionarios = cuestionariosSet.size;

        // Transforma los datos para que cada alumno tenga las variables "completado" y "notaMedia"
        const tablaAlumnosNotas = {};

        alumnos.forEach(alumno => {
          const { usuario_id, nombre, apellidos, nota, max_intento, fecha_ultimo_intento } = alumno;

          // Si el alumno no está aún en la tabla, lo creamos
          if (!tablaAlumnosNotas[usuario_id]) {
            tablaAlumnosNotas[usuario_id] = {
              id: usuario_id,
              nombreCompleto: `${nombre} ${apellidos}`,
              cuestionariosCompletados: 0,
              sumaNotas: 0,
              notaMedia: null,
              max_intento: 0,
              fecha_ultimo_intento: fecha_ultimo_intento,
              completado: `0/${totalCuestionarios}`,
            };
          }

          // Si el alumno tiene una nota en este cuestionario, la sumamos y contamos el cuestionario como completado
          if (nota !== null) {
            tablaAlumnosNotas[usuario_id].cuestionariosCompletados += 1;
            tablaAlumnosNotas[usuario_id].sumaNotas += nota;
          }
          if (max_intento !== null) {
            tablaAlumnosNotas[usuario_id].max_intento += max_intento;
          }

          if (fecha_ultimo_intento !== null) {
            if (fecha_ultimo_intento > tablaAlumnosNotas[usuario_id].fecha_ultimo_intento) {
              tablaAlumnosNotas[usuario_id].fecha_ultimo_intento = fecha_ultimo_intento;
            }
          }

          // Calculamos "completado" y "notaMedia"
          const completados = tablaAlumnosNotas[usuario_id].cuestionariosCompletados;
          tablaAlumnosNotas[usuario_id].completado = `${completados}/${totalCuestionarios}`;
          // Calculamos la nota media
          if (completados > 0) {
            let notaMedia = tablaAlumnosNotas[usuario_id].sumaNotas / completados;

            // Si la nota media es mayor a 10, la ajustamos a 10
            if (notaMedia > 10) {
              notaMedia = 10;
            }

            // Redondeamos la nota a un decimal
            tablaAlumnosNotas[usuario_id].notaMedia = notaMedia.toFixed(1);
          } else {
            tablaAlumnosNotas[usuario_id].notaMedia = null;
          }
        });

        // Convierte el objeto a una lista para su uso en el componente
        const tablaAlumnosArray = Object.keys(tablaAlumnosNotas).map(id => ({
          ...tablaAlumnosNotas[id],
        }));

        // Guarda la tabla transformada en el estado
        commit('SET_TABLA_ALUMNOS_NOTAS', tablaAlumnosArray);
        commit('SET_ALUMNOS_TOTAL', alumnos); // Guarda los alumnos si se requieren para otros usos
      } catch (error) {
        console.error('Error fetching alumnos:', error);
      }
    },
  },
  getters: {
    selectedOptionVista: (state) => state.selectedOptionVista, // Nuevo getter para acceder al estado de selectedOptionVista
    // -- Getters para curso y modulos
    curso: (state) => state.curso,
    loadingCurso: (state) => state.loadingCurso,
    moduls: (state) => state.moduls,
    loadingModuls: (state) => state.loadingModuls,

    // -- Getters para alumnos y notas
    alumnosTotal: (state) => state.alumnos_total,
    tablaAlumnosNotas: (state) => state.tabla_alumnos_notas,
  },
});