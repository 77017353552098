// src/i18n.js
import { createI18n } from 'vue-i18n';

// Mensajes de internacionalización
const messages = {
  es: {
    Acceder: 'Acceder',
    Accede_a_tu_perfil: 'Accede a tu perfil',
    Aceptable_aunque_puedes_mejorar: "Aceptable pero... ¡puedes mejorar!",
    Acceder_al_centro_sin_reserva: "Acceder al centro sin reserva",
    Acceso: "Acceso",
    Acceso_presencial: "Acceso presencial",
    Acceso_regulado_por_email: "Acceso regulado por correo electrónico",
    Acciones: 'Acciones',
    Acepto_VH: "Acepto que al acceder al Centro de Simulación Clínica Avanzada de Vall d'Hebron Barcelona Hospital Campus mediante los códigos QR generados en Simulacio.com, mis datos personales sean transferidos a la base de datos del centro. Confirmo mi acuerdo con las políticas de privacidad y cesión de derechos de imagen del Centro de Simulación.",
    Actividad_dirigida_por: "Actividad dirigida por",
    actividad: 'actividad',
    Actividad: 'Actividad',
    actividades: 'actividades',
    Actividades: 'Actividades',
    Actividades_completadas: 'Actividades completadas',
    Actividad_reservada_para_residentes: "Actividad reservada para residentes de Vall d'Hebron",
    actualizado_con_éxito: "actualizado con éxito",
    Añadir: 'Añadir',
    Añadir_lección: "Añadir lección",
    Añadir_módulo: "Añadir módulo",
    Año_de_residencia: "Año de residencia",
    alumnos: 'alumnos',
    Alumnos_pendientes_de_evaluación: "Alumnos pendientes de evaluación",
    anterior: 'anterior',
    Apellidos: 'Apellidos',
    Aprende: 'Aprende',
    Áreas: 'Áreas',
    Aún_no_eres_docente_de_ninguna_actividad: '¿Aún no eres docente de ninguna actividad? Asegúrate de que los datos de tu perfil estén completos y correctos.',
    Aún_no_te_has_inscrito_a_ninguna_actividad: 'Aún no te has inscrito a ninguna actividad.',
    Buen_esfuerzo: "¡Buen esfuerzo!",
    Calendario: 'Calendario',
    Cambiar_contraseña: 'Cambiar contraseña',
    Características_de_la_actividad: 'Características de la actividad',
    Características_del_módulo: 'Características del módulo',
    Características_generales: 'Características generales',
    Características_avanzadas_opcionales: 'Características avanzadas (opcional)',
    cardio_es: 'CardioSIM es un programa integral de formación para residentes de Cardiología que combina teoría y práctica en un entorno virtual y físico de simulación. Accede a contenidos teóricos, reserva tu espacio en los simuladores y realiza evaluaciones online, todo en una sola plataforma.',
    cargando_reservas: 'cargando reservas...',
    Cancelar_reserva: 'Cancelar reserva',
    Cerrar: 'Cerrar',
    Cerrar_sesión: 'Cerrar sesión',
    Cesion_de_derechos: 'Cesión de derechos',
    Código_QR: 'Código QR',
    Código_QR_de_acceso: 'Código QR de acceso al Centro',
    Comprueba: 'Comprueba',
    Condiciones_de_uso_y_privacidad: 'Condiciones de uso y privacidad',
    Condiciones_gineSIM: 'Condiciones de uso y política de privacidad de Simulacio.com',
    Confirmación: 'Confirmación',
    Confirmación_de_Correo_Electrónico: 'Confirmación de Correo Electrónico',
    Confirmando_correo_electrónico: "Confirmando correo electrónico",
    Confirmar_nueva_contraseña: 'Confirmar nueva contraseña',
    Contacto: 'Contacto',
    Contenido: 'Contenido',
    Contraseña: 'Contraseña',
    Contraseña_actual: 'Contraseña actual',
    Contraseña_restablecida_con_éxito: "¡Contraseña restablecida con éxito!",
    Corrección: 'Corrección',
    Correcto: 'Correcto',
    Correo_electrónico_confirmado_con_éxito: '¡Correo electrónico confirmado con éxito!',
    crea_activitat: 'Crea una actividad',
    creado_con_éxito: "creado con éxito",
    Crear: 'Crear',
    Crear_módulo: 'Crear módulo',
    cuestionario: 'cuestionario',
    datos: 'datos',
    Debes_seleccionar_al_menos_una_área_en_la_sección_de_visibilidad: "Debes seleccionar al menos un área en la sección de visibilidad.",
    Descargar: 'Descargar',
    Descripción: 'Descripción',
    Detalles_del_usuario: 'Detalles del usuario',
    día: 'día',
    Dificultad: 'Dificultad',
    Dirección: 'Dirección',
    Duración_de_la_actividad: "Duración de la actividad",
    DNI_opcional: 'DNI (opcional, para generar certificados)',
    Domina: 'Domina',
    Editar: 'Editar',
    el_día: 'el día',
    El_módulo: 'El módulo',
    Elige_un_día: 'Elige un día',
    eliminado_con_éxito: 'eliminado con éxito',
    Email: 'Correo electrónico',
    Email_institucional: 'Correo electrónico institucional',
    enviar: 'enviar',
    Enviar_solicitud: 'Enviar solicitud',
    Es_correcta: '¿Es correcta?',
    Esta_acción_eliminará_todas: "Esta acción eliminará todas sus lecciones y preguntas relacionadas de forma irreversible.",
    Estás_seguro: '¿Estás seguro/a?',
    Estás_seguro_que_quieres_eliminar: "¿Estás seguro/a que quieres eliminar",
    Evaluación: 'Evaluación',
    Evaluando_a: 'Evaluando a',
    Evaluar: 'Evaluar',
    Excelente: '!Excelente!',
    Explora: 'Explora',
    Explora_las_actividades: 'Explora las actividades',
    Externalizar: 'Externalizar',
    Fecha_de_fin: 'Fecha de fin',
    Fecha_de_inicio: 'Fecha de inicio',
    Fecha_inicio_especialidad: 'Fecha de inicio especialidad',
    Fecha_y_hora_de_inicio: "Fecha y hora de inicio",
    fue_actualizado_con_éxito: 'fue actualizado con éxito.',
    fue_creado_con_éxito: "fue creado con éxito.",
    gine_es: 'GineSIM es un programa integral de formación para residentes de Obstetricia y Ginecología que combina teoría y práctica en un entorno virtual y físico de simulación. Accede a contenidos teóricos, reserva tu espacio en los simuladores y realiza evaluaciones online, todo en una sola plataforma.',
    Guardar: 'Guardar',
    Guardar_cambios: 'Guardar cambios',
    He_leído_gineSIM: 'He leído las condiciones de uso y la política de privacidad del sitio y acepto que mis datos se almacenen en la base de datos de Simulacio.com.',
    Hola: 'Hola',
    Home: 'Inicio',
    Hora_de_inicio: 'Hora de inicio',
    Hora_de_fin: 'Hora de fin',
    hoy: 'hoy',
    Imagen: 'Imagen',
    Incorrecto: 'Incorrecto',
    Índice_de_contenidos: "Índice de contenidos",
    Información_de_contacto: 'Información de contacto',
    Iniciando_la_sesión: "Iniciando la sesión",
    Iniciar_sesión: 'Iniciar sesión',
    inicio: 'inicio',
    Inspira: 'Inspira',
    Inspira_tus_alumnos: 'Inspira a tus alumnos:',
    Inténtalo_de_nuevo: "¡Inténtalo de nuevo!",
    La_actividad: 'La actividad',
    la_actividad: "la actividad",
    La_leccion: 'La lección',
    lección: 'lección',
    lecciones: 'lecciones',
    libre: 'libre',
    listado: 'listado',
    Mejora_cardio: "Mejora tus habilidades en Cardiología en Vall d'Hebron Barcelona Hospital Campus.",
    Mejora_gine: "Mejora tus habilidades en Obstetricia y Ginecología en Vall d'Hebron Barcelona Hospital Campus.",
    Mejora_vallhebron: "Mejora tus habilidades prácticas y conocimientos en Vall d'Hebron Barcelona Hospital Campus.",

    mensaje_para_residentes_con_menos_experiencia: "Esta actividad está pensada para residentes con menos de {experiencia_maxima} años de experiencia en la especialidad.",
    mensaje_para_residentes_con_al_menos_experiencia: "Esta actividad está pensada para residentes que tienen al menos {experiencia_minima} años de experiencia en la especialidad.",
    mensaje_para_residentes_con_rango_experiencia: "Esta actividad está pensada para residentes que tienen entre {experiencia_minima} y {experiencia_maxima} años de experiencia en la especialidad.",

    mes: 'mes',
    Mirar: 'Mirar',
    módulo: 'módulo',
    modulos: 'módulos',
    No: 'No',
    No_eres_docente_de_ninguna_actividad: "No eres docente de ninguna actividad dirigida por otro profesional actualmente.",
    No_estás_inscrito_a_ninguna_actividad: "No estás inscrito a ninguna actividad actualmente.",
    No_has_creado_ninguna_actividad: "Todavía no has creado ninguna actividad.",
    No_hay_alumnos_pendientes_de_evaluación: "No hay alumnos pendientes de evaluación.",
    No_hay_disponibilidad_de_reservas_para_el_simulador_de: 'No hay disponibilidad de reservas para el simulador de',
    No_recuerdas_tu_c: '¿No recuerdas tu contraseña?',
    No_tienes_cuenta: '¿No tienes cuenta?',
    No_tienes_ningún_simulador_reservado: 'No tienes ningún simulador reservado.',
    Nombre: 'Nombre',
    Nombre_de_usuario: 'Nombre de usuario',
    Nota_final: 'Nota final',
    Nueva_contraseña: 'Nueva contraseña',
    Número_de: 'Número de',
    Número_de_alumnos: 'Número de alumnos',
    Número_de_docentes: 'Número de docentes',
    ocupación: 'ocupación',
    Orden_guardado_con_éxito: 'Orden guardado con éxito.',
    opción: 'opción',
    Opciones: 'Opciones',
    Opciones_adicionales: 'Opciones adicionales',
    Para_acceder_a_la_actividad: "Para acceder a la actividad contacta con el Centro de Simulación.",
    Para_más_detalles: 'Para más detalles, consulte la',
    Para_ti: 'Para ti',
    Pendiente: 'Pendiente',
    Perfil: 'Perfil',
    Ponte_a_prueba: "¡Ponte a prueba!",
    Política_VH: "Política de protección de datos de Vall d'Hebron Barcelona Hospital Campus",
    Practica: 'Practica',
    Preguntas: 'Preguntas',
    preguntas: 'preguntas',
    Profesional: 'Profesional',
    Profesionales: 'Profesionales',
    Puedes_ser_docente_de_esta_actividad: 'Puedes ser docente de esta actividad',
    puntos: 'puntos',
    Puntuación_práctica: 'Puntuación práctica',
    Puntuación_teórica: 'Puntuación teórica',
    Quieres_acceder_a_la_actividad: '¿Quieres acceder a la actividad',
    Recursos: 'Recursos',
    Restablecer_Contraseña: 'Restablecer Contraseña',
    Registrarse: 'Registrarse',
    Regístrate: "Regístrate",
    Registro: 'Registro',
    Reordenar: 'Reordenar',
    Reservar: 'Reservar',
    reservado: 'reservado',
    reservas: 'reservas',
    Reservas: 'Reservas',
    se_ha_eliminado_con_éxito: 'se ha eliminado con éxito.',
    Seguro_cancelar_reserva: '¿Seguro que quieres cancelar tu reserva para',
    Selecciona_un_día_para_ver_disponibilidad: 'Selecciona un día para ver disponibilidad',
    Selecciona_un_simulador: 'Selecciona un simulador',
    semana: 'semana',
    Seudónimo: 'Seudónimo',
    Sí: 'Sí',
    Si_eres_residente_de_vh: "Si eres residente de Vall d'Hebron, utiliza tu correo electrónico institucional.",
    siguiente: 'siguiente',
    Simuladores: 'Simuladores',
    Solicitar_acceso: "Solicitar acceso",
    Sólo_pueden_crear_actividades_los_profesionales_de_Vallhebron: "Sólo pueden crear actividades los professionales de Vall d'Hebron.",
    Teléfono: 'Teléfono',
    Tipo: 'Tipo',
    Tipo_de: 'Tipo de',
    Título: 'Título',
    Todavía_no_has_completado_ninguna_actividad: "Todavía no has completado ninguna actividad. ¡Anímate!",
    Tu_progreso: 'Tu progreso',
    Tus_actividades: 'Tus actividades',
    Tus_actividades_docentes: 'Tus actividades docentes',
    Tus_datos_se_han_actualizado_correctamente: "Tus datos se han actualizado correctamente.",
    Tus_reservas: 'Tus reservas',
    Usuarios: 'Usuarios',
    usuario: 'usuario',
    vallhebron_es: "Te damos la bienvenida a la APP del Centro de Simulación Clínica Avanzada del Hospital Vall d'Hebron. Accede a contenidos teóricos, reserva tu espacio en los simuladores y realiza evaluaciones online, todo en una sola plataforma.",
    Visión_general: 'Visión general',
    Vista_alumno: 'Vista alumno',
    Vista_docente: 'Vista docente',
    Visibilidad: 'Visibilidad',
    Vuelve_a_escribir_la_c: 'Vuelve a escribir la contraseña',
    y_la: 'y_la',
    Ya_tienes_una_cuenta: '¿Ya tienes una cuenta?',

    // Textos Aprende Inspira
    GineSIM_inspira_descripcion: "Aquí tienes la oportunidad de compartir tus conocimientos y experiencias con otros profesionales en formación.",
    Participa_Como_Docente: "Participa como docente",
    Participa_Como_Docente_descripcion: "Crea o colabora con la actividad docente que mejor se ajuste a tu experticia y prepárate para influir positivamente en el aprendizaje de tus alumnos.",
    Obtención_del_Certificado: "Obtención del certificado",
    Obtención_del_Certificado_descripcion: "Una vez completada la actividad y realizada la evaluación de los alumnos, puedes contactar con simulacio@vallhebron.cat para recibir tu certificado docente.",
    Propuesta_de_nuevas_actividades: "Propuesta de nuevas actividades",
    Propuesta_de_nuevas_actividades_descripcion: "Si tienes ideas para nuevas actividades que puedan enriquecer el programa educativo de GineSIM, envíame un email a alba.farras@vallhebron.cat",

    GineSIM_aprende_descripcion: "Una experiencia de aprendizaje integral que combina el conocimiento teórico con simulaciones prácticas avanzadas.",
    Aprendizaje_Interactivo_Online: "Aprendizaje interactivo online",
    Simulación_Práctica: "Simulación práctica",
    Eres_Residente: "¿Eres residente?",
    Aprendizaje_Interactivo_Online_descripcion: "Inicia tu formación con nuestro contenido teórico detallado y accesible en línea. Esta fase teórica te dotará de los conocimientos esenciales, preparándote para abordar prácticas más complejas y desafiantes.",
    Simulación_Práctica_descripcion: "Una vez asimilada la teoría, es el momento de aplicar tus conocimientos. Reserva tu lugar en el Centro de Simulación y sumérgete en escenarios realistas, diseñados para fortalecer tu habilidad práctica.",
    Eres_Residente_descripcion: "Si eres residente, te animamos a planificar con antelación para completar las actividades sugeridas antes de cada rotación clínica. Esto te proporcionará una base sólida y una preparación práctica que enriquecerá tu experiencia clínica.",

  },
  ca: {
    Acceder: 'Accedir',
    Accede_a_tu_perfil: 'Accedeix al teu perfil',
    Acceder_al_centro_sin_reserva: "Accedir al centre sense reserva",
    Acceso: "Accés",
    Acceso_presencial: "Accés presencial",
    Acceso_regulado_por_email: "Accés regulat per correu electrònic",
    Acciones: 'Accions',
    Aceptable_aunque_puedes_mejorar: "Acceptable... però pots millorar!",
    Acepto_VH: "Accepto que en accedir al Centre de Simulació Clínica Avançada de Vall d'Hebron Barcelona Hospital Campus mitjançant els codis QR generats a Simulacio.com, les meves dades personals siguin transferides a la base de dades del centre. Confirmo el meu acord amb les polítiques de privacitat i cessió de drets d'imatge del Centre de Simulació.",
    Actividad_dirigida_por: "Activitat dirigida per",
    actividad: 'activitat',
    Actividad: 'Activitat',
    actividades: 'activitats',
    Actividades: 'Activitats',
    Actividades_completadas: 'Activitats completades',
    Actividad_reservada_para_residentes: "Activitat reservada per a residents de Vall d'Hebron",
    actualizado_con_éxito: "actualitzat amb èxit",
    alumnos: 'alumnes',
    Alumnos_pendientes_de_evaluación: "Alumnes pendents d'avaluació",
    Añadir: 'Afegir',
    Añadir_lección: "Afegir lliçó",
    Añadir_módulo: "Afegir mòdul",
    Año_de_residencia: "Any de residència",
    anterior: 'anterior',
    Apellidos: 'Cognoms',
    Aprende: 'Aprèn',
    Áreas: 'Àrees',
    Aún_no_eres_docente_de_ninguna_actividad: "Encara no ets docent de cap activitat? Assegura't que les dades del teu perfil estiguin completes i correctes.",
    Aún_no_te_has_inscrito_a_ninguna_actividad: "Encara no t'has inscrit a cap activitat.",
    Buen_esfuerzo: "Bon esforç!",
    Calendario: 'Calendari',
    Cambiar_contraseña: 'Canviar contrasenya',
    Cancelar_reserva: 'Cancel·lar reserva',
    Características_de_la_actividad: "Característiques de l'activitat",
    Características_del_módulo: 'Característiques del mòdul',
    Características_generales: 'Característiques generals',
    Características_avanzadas_opcionales: 'Característiques avançades (opcional)',
    cardio_es: "CardioSIM és un programa integral de formació per a residents de Cardiologia que combina teoria i pràctica en un entorn virtual i físic de simulació. Accedeix a continguts teòrics, reserva el teu espai als simuladors i realitza avaluacions en línia, tot en una sola plataforma.",
    cargando_reservas: 'carregant reserves...',
    Cerrar: 'Tancar',
    Cerrar_sesión: 'Tancar sessió',
    Cesion_de_derechos: 'Cessió de drets',
    Código_QR: 'Codi QR',
    Código_QR_de_acceso: "Codi QR d'accés al Centre",
    Comprueba: 'Comprova',
    Confirmación: 'Confirmació',
    Confirmación_de_Correo_Electrónico: 'Confirmació de Correu Electrònic',
    Confirmando_correo_electrónico: "Confirmant correu electrònic",
    Confirmar_nueva_contraseña: 'Confirmar nova contrasenya',
    Contacto: 'Contacte',
    Condiciones_de_uso_y_privacidad: "Condicions d'ús i privacitat",
    Condiciones_gineSIM: "Condicions d'ús i política de privadesa de Simulacio.com",
    Contenido: 'Contingut',
    Contraseña: 'Contrasenya',
    Contraseña_actual: 'Contrasenya actual',
    Contraseña_restablecida_con_éxito: "La contrasenya s'ha restablert amb èxit!",
    Corrección: 'Correcció',
    Correcto: 'Correcte',
    Correo_electrónico_confirmado_con_éxito: 'Correu electrònic confirmat amb èxit!',
    crea_activitat: 'Crea una activitat',
    creado_con_éxito: "creat amb èxit",
    Crear: 'Crear',
    Crear_módulo: 'Crear mòdul',
    cuestionario: 'qüestionari',
    datos: 'dades',
    Debes_seleccionar_al_menos_una_área_en_la_sección_de_visibilidad: "Has de seleccionar al menys una àrea a la secció de visibilitat.",
    Descargar: 'Descarregar',
    Descripción: 'Descripció',
    Detalles_del_usuario: "Detalls de l'usuari",
    día: 'dia',
    Dificultad: 'Dificultat',
    Dirección: 'Direcció',
    DNI_opcional: 'DNI (opcional, per a generar certificats)',
    Domina: 'Domina',
    Duración_de_la_actividad: "Durada de l'activitat",
    Editar: 'Editar',
    Editar_módulo: 'Editar mòdul',
    el_día: 'el dia',
    El_módulo: 'El mòdul',
    Elige_un_día: 'Tria un dia',
    eliminado_con_éxito: 'eliminat amb èxit',
    Email: 'Correu electrònic',
    Email_institucional: 'Correu electrònic institucional',
    enviar: 'enviar',
    Enviar_solicitud: 'Enviar sol·licitud',
    Es_correcta: 'És correcta?',
    Esta_acción_eliminará_todas: "Aquesta acció eliminarà totes les seves lliçons i preguntes relacionades de manera irreversible.",
    Estás_seguro: "N'estàs segur/a?",
    Estás_seguro_que_quieres_eliminar: "N'estàs segur/a que vols eliminar",
    Evaluación: 'Avaluació',
    Evaluando_a: 'Avaluant a',
    Evaluar: 'Avaluar',
    Excelente: 'Excel·lent!',
    Explora: 'Explora',
    Explora_las_actividades: 'Explora les activitats',
    Externalizar: 'Externalitzar',
    Fecha_de_fin: 'Data de fi',
    Fecha_de_inicio: "Data d'inici",
    Fecha_inicio_especialidad: "Data d'inici de l'especialitat",
    Fecha_y_hora_de_inicio: "Data i hora d'inici",
    fue_actualizado_con_éxito: "s'ha actualitzat amb èxit.",
    fue_creado_con_éxito: "s'ha creat amb èxit.",
    gine_es: "GineSIM és un programa integral de formació per a residents d'Obstetrícia i Ginecologia que combina teoria i pràctica en un entorn virtual i físic de simulació. Accedeix a continguts teòrics, reserva el teu espai als simuladors i realitza avaluacions en línia, tot en una sola plataforma.",
    Guardar: 'Desar',
    Guardar_cambios: 'Desar els canvis',
    He_leído_gineSIM: "He llegit les condicions d'ús i la política de privacitat del lloc i accepto que les meves dades s'emmagatzemin a la base de dades de Simulacio.com.",
    Hola: 'Hola',
    Home: 'Inici',
    Hora_de_inicio: "Hora d'inici",
    Hora_de_fin: "Hora de fi",
    hoy: 'avui',
    Imagen: 'Imatge',
    Incorrecto: 'Incorrecte',
    Índice_de_contenidos: "Índex de continguts",
    Información_de_contacto: 'Informació de contacte',
    Iniciando_la_sesión: "Iniciant la sessió",
    Iniciar_sesión: 'Iniciar sessió',
    inicio: 'inici',
    Inspira: 'Inspira',
    Inspira_tus_alumnos: 'Inspira els teus alumnes:',
    Inténtalo_de_nuevo: "Intenta-ho una altra vegada!",
    La_actividad: "L'activitat",
    la_actividad: "l'activitat",
    La_leccion: 'La lliçó',
    lección: 'lliçó',
    lecciones: 'lliçons',
    libre: 'lliure',
    listado: 'llistat',

    mensaje_para_residentes_con_menos_experiencia: "Aquesta activitat està pensada per a residents amb menys de {experiencia_maxima} anys d'experiència en l'especialitat.",
    mensaje_para_residentes_con_al_menos_experiencia: "Aquesta activitat està pensada per a residents que tenen almenys {experiencia_minima} anys d'experiència en l'especialitat.",
    mensaje_para_residentes_con_rango_experiencia: "Aquesta activitat està pensada per a residents que tenen entre {experiencia_minima} i {experiencia_maxima} anys d'experiència en l'especialitat.",

    mes: 'mes',
    Mejora_cardio: "Millora les teves habilitats en Cardiología a Vall d'Hebron Barcelona Hospital Campus.",
    Mejora_vallhebron: "Millora les teves habilitats pràctiques i coneixements a Vall d'Hebron Barcelona Hospital Campus.",
    Mejora_gine: "Millora les teves habilitats en Obstetrícia i Ginecologia a Vall d'Hebron Barcelona Hospital Campus.",
    Mirar: 'Mirar',
    modulos: 'mòduls',
    módulo: 'mòdul',
    No: 'No',
    No_eres_docente_de_ninguna_actividad: "No ets docent de cap activitat dirigida per un altre professional actualment.",
    No_estás_inscrito_a_ninguna_actividad: "No estàs inscrit a cap activitat actualment.",
    No_has_creado_ninguna_actividad: "Encara no has creat cap activitat.",
    No_hay_alumnos_pendientes_de_evaluación: "No hi ha alumnes pendents d'avaluació.",
    No_hay_disponibilidad_de_reservas_para_el_simulador_de: 'No hi ha disponibilitat de reserves pel simulador de',
    No_recuerdas_tu_c: 'No recordes la teva contrasenya?',
    No_tienes_cuenta: '¿No tens compte?',
    No_tienes_ningún_simulador_reservado: 'No tens cap simulador reservat.',
    Nombre: 'Nom',
    Nombre_de_usuario: "Nom d'usuari",
    Nota_final: 'Nota final',
    Nueva_contraseña: 'Nova contrasenya',
    Número_de: "Número de",
    Número_de_alumnos: "Número d'alumnes",
    Número_de_docentes: "Número de docents",
    ocupación: 'ocupació',
    opción: 'opció',
    Opciones: 'Opcions',
    Opciones_adicionales: 'Opcions addicionals',
    Orden_guardado_con_éxito: 'Ordre desat amb èxit.',
    Para_acceder_a_la_actividad: "Per a accedir a l'activitat contacta amb el Centre de Simulació.",
    Para_más_detalles: 'Per a més detalls, consulti la',
    Para_ti: 'Per a tu',
    Pendiente: 'Pendent',
    Perfil: 'Perfil',
    Política_VH: "Política de protecció de dades de Vall d'Hebron Barcelona Hospital Campus",
    Ponte_a_prueba: "Posa't a prova!",
    Practica: 'Practica',
    Preguntas: 'Preguntes',
    preguntas: 'preguntes',
    Profesional: 'Professional',
    Profesionales: 'Professionals',
    Puedes_ser_docente_de_esta_actividad: "Pots ser docent d'aquesta activitat",
    puntos: 'punts',
    Puntuación_práctica: 'Puntuació pràctica',
    Puntuación_teórica: 'Puntuació teòrica',
    Quieres_acceder_a_la_actividad: "Vols accedir a l'activitat",
    Recursos: 'Recursos',
    Restablecer_Contraseña: 'Restablir Contrasenya',
    Registrarse: 'Registrar-se',
    Regístrate: "Registra't",
    Registro: 'Registre',
    Reordenar: 'Reordenar',
    Reservar: 'Reservar',
    reservado: 'reservat',
    reservas: 'reserves',
    Reservas: 'Reserves',
    se_ha_eliminado_con_éxito: "s'ha eliminat amb èxit.",
    Seguro_cancelar_reserva: 'Segur que vols cancel·lar la teva reserva per a',
    Selecciona_un_día_para_ver_disponibilidad: 'Selecciona un dia per a veure disponibilitat',
    Selecciona_un_simulador: 'Selecciona un simulador',
    semana: 'setmana',
    Seudónimo: 'Pseudònim',
    Sí: 'Sí',
    Si_eres_residente_de_vh: "Si ets resident de Vall d'Hebron, utilitza el teu correu electrònic institucional.",
    siguiente: 'següent',
    Simuladores: 'Simuladors',
    Solicitar_acceso: "Sol·licitar accés",
    Sólo_pueden_crear_actividades_los_profesionales_de_Vallhebron: "Només poden crear activitats els professionals de Vall d'Hebron.",
    Teléfono: 'Telèfon',
    Tipo: 'Tipus',
    Tipo_de: 'Tipus de',
    Título: 'Títol',
    Todavía_no_has_completado_ninguna_actividad: "Encara no has completat cap activitat. Anima-t'hi!",
    Tu_progreso: 'El teu progrés',
    Tus_actividades: 'Les teves activitats',
    Tus_actividades_docentes: 'Les teves activitats docents',
    Tus_datos_se_han_actualizado_correctamente: "Les teves dades s'han actualitzat correctament.",
    Tus_reservas: 'Les teves reserves',
    Usuarios: 'Usuaris',
    usuario: 'usuari',
    vallhebron_es: "Et donem la benvinguda a l'APP del Centre de Simulació Clínica Avançada de l'Hospital Vall d'Hebron. Accedeix a continguts teòrics, reserva el teu espai als simuladors i realitza avaluacions en línia, tot en una sola plataforma.",
    Visibilidad: 'Visibilitat',
    Visión_general: 'Visió general',
    Vista_alumno: 'Vista alumne',
    Vista_docente: 'Vista docent',
    Vuelve_a_escribir_la_c: 'Torna a escriure la contrasenya',
    y_la: 'i la',
    Ya_tienes_una_cuenta: 'Ja tens un compte?',

    // Textos Aprende Inspira
    GineSIM_inspira_descripcion: "Aquí tens l'oportunitat de compartir els teus coneixements i experiències amb altres professionals en formació.",
    Participa_Como_Docente: "Participa com a docent",
    Participa_Como_Docente_descripcion: "Crea o col·labora amb l'activitat docent que millor s'ajusti a la teva expertesa i prepara't per a influir positivament en l'aprenentatge dels teus alumnes.",
    Obtención_del_Certificado: "Obtenció del certificat",
    Obtención_del_Certificado_descripcion: "Una vegada completada l'activitat i realitzada l'avaluació dels alumnes, pots contactar amb simulacio@vallhebron.cat per rebre el teu certificat docent.",
    Propuesta_de_nuevas_actividades: "Proposta de noves activitats",
    Propuesta_de_nuevas_actividades_descripcion: "Si tens idees per a noves activitats que puguin enriquir el programa educatiu de GineSIM, envia'm un email a alba.farras@vallhebron.cat",

    GineSIM_aprende_descripcion: "Una experiència d'aprenentatge integral que combina el coneixement teòric amb simulacions pràctiques avançades.",
    Aprendizaje_Interactivo_Online: "Aprenentatge interactiu en línia",
    Simulación_Práctica: "Simulació pràctica",
    Eres_Residente: "Ets resident?",
    Aprendizaje_Interactivo_Online_descripcion: "Inicia la teva formació amb el nostre contingut teòric detallat i accessible en línia. Aquesta fase teòrica t'equiparà amb els coneixements essencials, preparant-te per abordar pràctiques més complexes i desafiants.",
    Simulación_Práctica_descripcion: "Un cop assimilada la teoria, és el moment d'aplicar els teus coneixements. Reserva el teu lloc al Centre de Simulació i submergeix-te en escenaris realistes, dissenyats per enfortir la teva habilitat pràctica.",
    Eres_Residente_descripcion: "Si ets resident, t'animem a planificar amb antelació per completar les activitats suggerides abans de cada rotació clínica. Això et proporcionarà una base sòlida i una preparació pràctica que enriquirà la teva experiència clínica.",

  },
  en: {
    Acceder: 'Access',
    Accede_a_tu_perfil: 'Access your profile',
    Acceder_al_centro_sin_reserva: "Access the center without reservation",
    Acceso: "Access",
    Acceso_presencial: "Presential access",
    Acceso_regulado_por_email: "Email regulated access",
    Acciones: 'Actions',
    Aceptable_aunque_puedes_mejorar: "Fine, but you can improve it!",
    Acepto_VH: "I agree that by accessing the Advanced Clinical Simulation Center of Vall d'Hebron Barcelona Hospital Campus using the QR codes generated in Simulacio.com, my personal data will be transferred to the center's database. I confirm my agreement with the privacy policies and image rights assignment of the Simulation Center.",
    Actividad_dirigida_por: "Activity directed by",
    actividad: 'activity',
    Actividad: 'Activity',
    actividades: 'activities',
    Actividades: 'Activities',
    Actividades_completadas: 'Completed activities',
    Actividad_reservada_para_residentes: "Activity reserved for Vall d'Hebron residents",
    actualizado_con_éxito: "successfully updated",
    alumnos: 'students',
    Alumnos_pendientes_de_evaluación: "Students pending evaluation",
    Añadir: 'Add',
    Añadir_lección: "Add lesson",
    Añadir_módulo: "Add section",
    Año_de_residencia: "Year of training",
    anterior: 'previous',
    Apellidos: 'Surname',
    Aprende: 'Learn',
    Áreas: 'Areas',
    Aún_no_eres_docente_de_ninguna_actividad: "Are you still not a teacher of any activity? Make sure that your profile details are complete and correct.",
    Aún_no_te_has_inscrito_a_ninguna_actividad: "You haven't enrolled in any activity yet.",
    Buen_esfuerzo: "Good attempt!",
    Calendario: 'Calendar',
    Cambiar_contraseña: 'Change password',
    Cancelar_reserva: 'Cancel reservation',
    Características_de_la_actividad: "Activity settings",
    Características_del_módulo: 'Module settings',
    Características_generales: 'General settings',
    Características_avanzadas_opcionales: 'Advanced settings (optional)',
    cardio_es: 'CardioSIM is a comprehensive training program for Cardiology residents that combines theory and practice in a virtual and physical simulation environment. Access theoretical content, reserve your space in simulators, and take online assessments, all on a single platform.',
    cargando_reservas: 'loading reservations...',
    Cerrar: 'Close',
    Cerrar_sesión: 'Log out',
    Cesion_de_derechos: 'Image and exploitation rights',
    Código_QR: 'QR Code',
    Código_QR_de_acceso: 'Access QR Code to the Center',
    Comprueba: 'Check',
    Condiciones_de_uso_y_privacidad: 'Terms of Use and Privacy',
    Condiciones_gineSIM: 'Simulacio.com Terms of Use and Privacy Policy',
    Confirmación: 'Confirmation',
    Confirmación_de_Correo_Electrónico: 'Email Confirmation',
    Confirmando_correo_electrónico: "Confirming email",
    Confirmar_nueva_contraseña: 'Confirm new password',
    Contacto: 'Contact',
    Contenido: 'Content',
    Contraseña: 'Password',
    Contraseña_actual: 'Current Password',
    Contraseña_restablecida_con_éxito: "Password was successfully reset!",
    Corrección: 'Correction',
    Correcto: 'Correct',
    Correo_electrónico_confirmado_con_éxito: 'Email successfully confirmed!',
    crea_activitat: 'Create new activity',
    creado_con_éxito: "successfully created",
    Crear: 'Create',
    Crear_módulo: 'Create section',
    cuestionario: 'test',
    datos: 'dades',
    Debes_seleccionar_al_menos_una_área_en_la_sección_de_visibilidad: "You must select at least one area in the visibility section.",
    Descargar: 'Download',
    Descripción: 'Description',
    Detalles_del_usuario: 'User Details',
    día: 'day',
    Dificultad: 'Difficulty',
    Dirección: 'Address',
    DNI_opcional: 'DNI (optional, for generating certificates)',
    Domina: 'Master',
    Duración_de_la_actividad: "Activity duration",
    Editar: 'Edit',
    Editar_módulo: 'Edit module',
    el_día: 'on the day',
    El_módulo: 'The module',
    Elige_un_día: 'Choose a date',
    eliminado_con_éxito: 'successfully deleted',
    Email: 'Email',
    Email_institucional: 'Institutional email',
    enviar: 'send',
    Enviar_solicitud: 'Send request',
    Es_correcta: 'Is it correct?',
    Esta_acción_eliminará_todas: "This action will irreversibly delete all your lessons and related questions.",
    Estás_seguro: "Are you sure?",
    Estás_seguro_que_quieres_eliminar: "Are you sure you want to delete",
    Evaluación: 'Evaluation',
    Evaluando_a: 'Evaluating',
    Evaluar: 'Evaluate',
    Excelente: 'Excellent!',
    Explora: 'Explore',
    Explora_las_actividades: 'Explore activities',
    Externalizar: 'Externalize',
    Fecha_de_fin: 'Ending date',
    Fecha_de_inicio: "Start date",
    Fecha_inicio_especialidad: 'Specialty Start Date',
    Fecha_y_hora_de_inicio: "Start date and time",
    fue_actualizado_con_éxito: 'was successfully updated.',
    fue_creado_con_éxito: "has been successfully created.",
    gine_es: 'GineSIM is a comprehensive training program for Obstetrics and Gynecology residents that combines theory and practice in a virtual and physical simulation environment. Access theoretical content, reserve your space in simulators, and take online assessments, all on a single platform.',
    Guardar: 'Save',
    Guardar_cambios: 'Save changes',
    He_leído_gineSIM: 'I have read the terms of use and privacy policy of the site and agree that my data be stored in the Simulacio.com database.',
    Hola: 'Hello',
    Home: 'Home',
    Hora_de_inicio: "Start time",
    Hora_de_fin: "Ending time",
    hoy: 'today',
    Incorrecto: 'Incorrect',
    Inténtalo_de_nuevo: "Try again!",
    Imagen: 'Image',
    Índice_de_contenidos: "Index of contents",
    Información_de_contacto: 'Contact Information',
    Iniciando_la_sesión: "Logging in",
    Iniciar_sesión: 'Sign in',
    inicio: 'home',
    Inspira: 'Inspire',
    Inspira_tus_alumnos: 'Inspire your students:',
    La_actividad: 'The activity',
    la_actividad: "the activity",
    La_leccion: 'The lesson',
    lección: 'lesson',
    lecciones: 'lessons',
    libre: 'free',
    listado: 'list',
    Mejora_cardio: "Improve your skills in Cardiología at Vall d'Hebron Barcelona Hospital Campus.",
    Mejora_vallhebron: "Improve your practical skills and knowledge at Vall d'Hebron Barcelona Hospital Campus.",
    Mejora_gine: "Improve your skills in Obstetrics and Gynecology at Vall d'Hebron Barcelona Hospital Campus.",

    mensaje_para_residentes_con_menos_experiencia: "This activity is intended for residents with less than {experiencia_maxima} years of experience in the specialty.",
    mensaje_para_residentes_con_al_menos_experiencia: "This activity is intended for residents who have at least {experiencia_minima} years of experience in the specialty.",
    mensaje_para_residentes_con_rango_experiencia: "This activity is intended for residents who have between {experiencia_minima} and {experiencia_maxima} years of experience in the specialty.",

    mes: 'month',
    Mirar: 'View',
    modulos: 'modules',
    módulo: 'module',
    No: 'No',
    No_eres_docente_de_ninguna_actividad: "You are not a teacher for any activity directed by another professional currently.",
    No_estás_inscrito_a_ninguna_actividad: "You are not currently enrolled in any activity.",
    No_has_creado_ninguna_actividad: "You haven't created any activities yet.",
    No_hay_alumnos_pendientes_de_evaluación: "No students pending evaluation.",
    No_hay_disponibilidad_de_reservas_para_el_simulador_de: 'There is no reservation availability for the simulator of',
    No_recuerdas_tu_c: "Don't remember your password?",
    No_tienes_cuenta: "Don't have an account?",
    No_tienes_ningún_simulador_reservado: 'You have no simulator reserved.',
    Nombre: 'Name',
    Nombre_de_usuario: 'Username',
    Nota_final: 'Final mark',
    Nueva_contraseña: 'New Password',
    Número_de: 'Number of',
    Número_de_alumnos: 'Number of students',
    Número_de_docentes: 'Number of teachers',
    ocupación: 'occupation',
    opción: 'option',
    Opciones: 'Options',
    Opciones_adicionales: 'Additional options',
    Orden_guardado_con_éxito: 'Order successfully saved.',
    Para_acceder_a_la_actividad: "To access the activity, contact the Simulation Center.",
    Para_más_detalles: 'For more details, please see the',
    Para_ti: 'For you',
    Pendiente: 'Pending',
    Perfil: 'Profile',
    Política_VH: "Privacy Policy of Vall d'Hebron Barcelona Hospital Campus",
    Ponte_a_prueba: "Challenge yourself!",
    Practica: 'Practice',
    Preguntas: 'Questions',
    preguntas: 'questions',
    Profesional: 'Professional',
    Profesionales: 'Professionals',
    Puedes_ser_docente_de_esta_actividad: "You may be a teacher in this activity.",
    puntos: 'points',
    Puntuación_práctica: 'Practical score',
    Puntuación_teórica: 'Theoretical score',
    Quieres_acceder_a_la_actividad: "Do you want to access the activity",
    Recursos: 'Resources',
    Restablecer_Contraseña: 'Reset Password',
    Registrarse: 'Sign up',
    Regístrate: 'Register',
    Registro: 'Registration',
    Reordenar: 'Reorder',
    Reservar: 'Reserve slot',
    reservado: 'reserved',
    reservas: 'reservations',
    Reservas: 'Reservations',
    se_ha_eliminado_con_éxito: 'has been successfully deleted.',
    Seguro_cancelar_reserva: 'Are you sure you want to cancel your reservation for',
    Selecciona_un_día_para_ver_disponibilidad: 'Select a day to view availability',
    Selecciona_un_simulador: 'Select a simulator',
    semana: 'week',
    Seudónimo: 'Nickname',
    Sí: 'Yes',
    Si_eres_residente_de_vh: "If you are a Vall d'Hebron resident, use your institutional email.",
    siguiente: 'next',
    Simuladores: 'Simulators',
    Solicitar_acceso: "Request Access",
    Sólo_pueden_crear_actividades_los_profesionales_de_Vallhebron: "Only Vall d'Hebron professionals can create new activities.",
    Teléfono: 'Phone',
    Tipo: 'Type',
    Tipo_de: 'Type of',
    Título: 'Title',
    Todavía_no_has_completado_ninguna_actividad: "You haven't completed any activity yet. Go for it!",
    Tu_progreso: 'Your progress',
    Tus_actividades: 'Your activities',
    Tus_actividades_docentes: 'Your teaching activities',
    Tus_datos_se_han_actualizado_correctamente: "Your data has been successfully updated.",
    Tus_reservas: 'Your reservations',
    Usuarios: 'Users',
    usuario: 'user',
    vallhebron_es: "We welcome you to the APP of the Advanced Clinical Simulation Center of the Vall d'Hebron Hospital. Access theoretical content, book your space in the simulators, and take online assessments, all in one platform.",
    Visión_general: 'Overview',
    Visibilidad: 'Visibility',
    Vista_alumno: 'Student view',
    Vista_docente: 'Teacher view',
    Vuelve_a_escribir_la_c: 'Retype the password',
    y_la: 'and the',
    Ya_tienes_una_cuenta: 'Already have an account?',

    // Textos Aprende Inspira
    GineSIM_inspira_descripcion: "Here you have the opportunity to share your knowledge and experiences with other professionals in training.",
    Participa_Como_Docente: "Participate as a teacher",
    Participa_Como_Docente_descripcion: "Create or collaborate with the teaching activity that best fits your expertise and get ready to positively influence your students' learning.",
    Obtención_del_Certificado: "Certificate obtention",
    Obtención_del_Certificado_descripcion: "Once the activity is completed and the students' evaluation is done, you can contact simulacio@vallhebron.cat to receive your teaching certificate.",
    Propuesta_de_nuevas_actividades: "Proposal of new activities",
    Propuesta_de_nuevas_actividades_descripcion: "If you have ideas for new activities that can enrich the GineSIM educational program, send me an email to alba.farras@vallhebron.cat",

    GineSIM_aprende_descripcion: "A comprehensive learning experience that combines theoretical knowledge with advanced practical simulations.",
    Aprendizaje_Interactivo_Online: "Online interactive learning",
    Simulación_Práctica: "Practical simulation",
    Eres_Residente: "Are you a resident?",
    Aprendizaje_Interactivo_Online_descripcion: "Start your training with our detailed and accessible online theoretical content. This theoretical phase will equip you with the essential knowledge, preparing you to tackle more complex and challenging practices.",
    Simulación_Práctica_descripcion: "Once you've assimilated the theory, it's time to apply your knowledge. Book your spot at the Simulation Center and immerse yourself in realistic scenarios designed to strengthen your practical skills.",
    Eres_Residente_descripcion: "If you are a resident, we encourage you to plan ahead to complete the suggested activities before each clinical rotation. This will provide you with a solid foundation and practical preparation that will enrich your clinical experience.",
  }

};

// Detectar el idioma preferido del navegador
const browserLocale = navigator.language.split('-')[0];

// Crear la instancia i18n con las opciones de configuración
const i18n = createI18n({
  legacy: false, // Usa la API de composición para Vue 3
  locale: browserLocale,
  fallbackLocale: 'es', // idioma de respaldo
  messages, // los mensajes de traducción
});

export default i18n;

