<template>
    <template v-if="curso.afos">
        <v-btn block class="text-wrap" color="green" style="word-break: break-word" @click="showQrCode"
            prepend-icon="mdi-qrcode">{{ t('Acceso_presencial') }} ({{
                selectedOptionVista }})</v-btn>
        <!-- Modal para el QR -->
        <v-dialog v-model="showQrModal" persistent max-width="600">
            <v-card>
                <template v-if="!consent">
                    <v-card-text class="text-center">
                        <p>{{ t('Acepto_VH') }}</p>
                        <p>{{ t('Para_más_detalles') }} <a
                                href="https://www.vallhebron.com/politica-de-proteccio-de-dades" target="_blank">{{
                                    t('Política_VH') }}</a> {{ t('y_la') }} <a
                                href="https://qrsimulacio.vallhebron.cat/imatge.html" target="_blank">{{
                                    t('Cesion_de_derechos') }}</a>.</p>
                    </v-card-text>
                </template>
                <div id="qr-code-container" ref="qrCodeContainer">
                    <template v-if="consent">
                        <v-card-title class="text-center mt-3">{{ t('Código_QR_de_acceso') }}</v-card-title>
                        <v-card-text class="text-center">
                            <vue-qrcode :value="currentQrValue"></vue-qrcode>
                            <div>{{ curso.titulo }}</div>
                            <div>{{ t('Acceder_al_centro_sin_reserva') }}</div>
                        </v-card-text>
                    </template>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!consent" color="secondary" text @click="accept">{{ t('Aceptar') }}</v-btn>
                    <v-btn v-if="consent" color="secondary" text @click="downloadQr">{{ t('Descargar') }}</v-btn>
                    <v-btn color="primary" text @click="closeQrModal">{{ t('Cerrar') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>
    <template v-if="!curso.afos && selectedOptionVista === 'docente'">
        <v-alert v-if="!alumnos_pendientes || alumnos_pendientes.length === 0" type="info">
            Para configurar un código QR de acceso presencial tienes que introducir el código AFOS en la configuración
            avanzada (símbolo engranaje).
        </v-alert>
    </template>
</template>

<script setup>
import html2canvas from 'html2canvas';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const props = defineProps({
    curso: {
        type: Object
    },
    selectedOptionVista: String,
});

const { t } = useI18n();
const showQrModal = ref(false);
const consent = ref(false);
const currentQrValue = ref('');
const qrCodeContainer = ref(null);

function showQrCode() {
    axios.post('/generate_qr/', {
        afos: props.curso.afos,
        selectedOption: props.selectedOptionVista
    }).then(response => {
        currentQrValue.value = response.data.combined_codiqr;
        showQrModal.value = true;
    }).catch(error => {
        console.error('Error al generar QR: ', error);
    });
}

function closeQrModal() {
    showQrModal.value = false;
}

function accept() {
    consent.value = true;
}

function downloadQr() {
    html2canvas(qrCodeContainer.value).then(canvas => {
        let link = document.createElement('a');
        link.download = 'qr-code.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
    }).catch(err => {
        console.error('Error capturing QR: ', err);
    });
}
</script>