<template>
  <v-container class="fill-height">
    <v-app-bar color="primary" :image="curso.imagen_url">
      <template v-slot:prepend>
        <v-avatar class="elevation-1" size="x-large" @click="selectModulo(null, null)">
          <img :src="curso.imagen_url" style="object-fit: cover; width: 100%; height: 100%;">
        </v-avatar>
      </template>
      <v-app-bar-title @click="selectModulo(null, null)">{{ curso.titulo }}</v-app-bar-title>
      <template v-slot:image>
        <v-img gradient="to right, rgba(0,0,0,.7), rgba(255,255,255,.7)"></v-img>
      </template>
      <v-btn v-if="selectedOption === 'editar'" color="primary" icon="mdi-cog" variant="text"
        @click="openEditDialog"></v-btn>
      <v-btn color="primary" icon="mdi-home" variant="text"
        @click="selectModulo(null, null)"></v-btn>
      <v-btn v-if="!drawer" icon="mdi-dots-vertical" color="secondary" @click="drawer = true"></v-btn>
      <v-btn-toggle v-if="curso.docente_tiene_acceso" mandatory v-model="selectedOptionVista" color="primary" group>
        <v-btn v-for="option in optionsVista" :key="option.value" :value="option.value" variant="text">
          <span class="hidden-sm-and-down">{{ t(option.label) }}</span>
          <v-icon>
            {{ option.icon }}
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <template v-if="curso.permiso_edicion">
        <v-btn-toggle mandatory v-model="selectedOption" color="primary">
          <v-btn v-for="option in options" :key="option.value" :value="option.value" :icon="option.icon" variant="text">
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" :permanent="display.mdAndUp.value">
      <v-list nav v-if="loadingCurso">
        <v-skeleton-loader type="avatar, text"></v-skeleton-loader>
      </v-list>
      <v-list nav v-if="!loadingCurso">
        <v-list-item v-for="(modulo, mI) in moduls.modulos" :key="modulo.id">
          <v-list-item @click="selectModulo(modulo, mI)" base-color="primary" :value="modulo" :active="moduloIndex === mI">{{ modulo.titulo
            }}
          </v-list-item>
          <template v-if="moduloIndex === mI">
          <v-list-item v-for="(leccion, lI) in modulo.lecciones" :key="leccion.id"
            @click="selectLeccion(leccion, mI, lI)" :value="leccion" :active="moduloIndex === mI && leccionIndex === lI">
            <v-list-item-title>{{ leccion.titulo }}</v-list-item-title>
            <template v-if="leccion.cuestionario" v-slot:append>
              <CuestionarioCurso :navbarra="true" :IDcuestionario="leccion.cuestionario"
                :nota_usuario="leccion.nota_usuario" :IDleccion="leccion.id" :selectedOption="selectedOption"
                :selectedOptionVista="selectedOptionVista" :cursoId="cursoId" @respuesta-enviada="fetchModuls"
                @nuevo-cuestionario="handleNuevoCuestionario" @eliminar-cuestionario="handleEliminarCuestionario">
              </CuestionarioCurso>
            </template>
          </v-list-item>
          <v-list-item v-if="selectedOption === 'editar'" @click="() => openAddDialog('lecciones', modulo.id)">
            <v-list-item-title>{{ t('Añadir') }} {{ t('lección') }}</v-list-item-title>
            <template v-slot:append>
              <v-icon color="primary" icon="mdi-plus"></v-icon>
            </template>
          </v-list-item>
        </template>
        </v-list-item>
        <v-list-item v-if="selectedOption === 'editar'" @click="() => openAddDialog('modulos')">
          <v-list-item-title>{{ t('Añadir') }} {{ t('módulo') }}</v-list-item-title>
          <template v-slot:append>
            <v-icon color="secondary" icon="mdi-plus"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-row v-if="loadingCurso">
      <v-skeleton-loader type="image, article"></v-skeleton-loader>
    </v-row>
    <v-row v-if="!loadingCurso">
      <!-- Prova nou modul -->
      <ModuloLeccion v-if="!leccionActual" :curso="curso" :moduls="moduls" :moduloIndex="moduloIndex"
        :selectedOption="selectedOption" :selectedOptionVista="selectedOptionVista" :drawer="drawer"
        @update:drawer="drawer = $event" @update-modulo="handleUpdateModulo" @delete-modulo="handleDeleteModulo"
        @reorder-modules="handleReorder" @refrescar="fetchModuls"></ModuloLeccion>
      <template v-if="leccionActual">
        <LeccionContent :curso="curso" :moduls="moduls" :moduloIndex="moduloIndex" :leccionIndex="leccionIndex"
          :selectedOption="selectedOption" :leccionActual="leccionActual" @update-leccion="handleUpdateLeccion"
          @delete-leccion="handleDeleteLeccion" @reorder-lecciones="handleReorderL">
        </LeccionContent>
        <CuestionarioCurso :navbarra="false" :IDcuestionario="leccionActual.cuestionario" :IDleccion="leccionActual.id"
          :selectedOption="selectedOption" :selectedOptionVista="selectedOptionVista" :cursoId="cursoId"
          @respuesta-enviada="fetchModuls" @nuevo-cuestionario="handleNuevoCuestionario"
          @eliminar-cuestionario="handleEliminarCuestionario">
        </CuestionarioCurso>
      </template>

      <v-col v-if="moduloActual || leccionActual" class="d-flex justify-center align-end" cols="12">
        <v-card class="mb-4">
          <v-card-actions>
            <v-btn prepend-icon="mdi-chevron-left" @click="selectPreviousLeccion" :disabled="!hasPreviousLeccion">
              <span class="d-none d-sm-inline">{{ t('anterior') }}</span>
            </v-btn>
            <v-btn icon v-if="!drawer" color="primary" @click.stop="drawer = true">
              <v-icon icon="mdi-dots-horizontal"></v-icon>
            </v-btn>
            <v-btn append-icon="mdi-chevron-right" @click="selectNextLeccion" :disabled="!hasNextLeccion">
              <span class="d-none d-sm-inline">{{ t('siguiente') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" fullscreen persistent>
      <v-card>
        <HelperCarregant v-if="loadingModuls" :missatge="t('Actualizando') + ' ' + t('actividad')" />
        <template v-if="!loadingModuls">
          <v-toolbar flat>
            <v-toolbar-title>{{ t('Características_de_la_actividad') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="updateCurso(false)" prepend-icon="mdi-content-save">
              {{ t('Guardar') }}
            </v-btn>
            <v-btn icon @click="showDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel :title="t('Características_generales')">
                  <v-expansion-panel-text>
                    <v-text-field v-model="cursoData.titulo" :label="t('Título')" :disabled="dialogType === 'delete'"
                      required></v-text-field>
                    <v-text-field v-model="curso.nombre_director" :label="t('Director')" readonly
                      append-icon="mdi-information" @click:append="() => openHelpDialog('director')"></v-text-field>
                    <v-textarea v-model="cursoData.descripcion" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('descripcion')" :label="t('Descripción')"
                      :disabled="dialogType === 'delete'" required></v-textarea>
                    <v-textarea v-model="cursoData.presentacion" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('presentacion')" :label="t('Presentación')"
                      :disabled="dialogType === 'delete'" required></v-textarea>
                    <v-file-input v-model="cursoData.imagen" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('imagen')" :label="t('Imagen')" prepend-icon="mdi-camera"
                      accept="image/*" @change="handleFileUpload"></v-file-input>
                    <!-- Verifica si hay una URL de imagen y muestra el thumbnail -->
                    <v-img :src="cursoData.imagen_thub" height="100" />
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <template v-slot:default>
                      <v-row no-gutters>
                        <v-col class="d-flex justify-start" cols="4">
                          {{ t('Acceso') }}
                        </v-col>
                        <v-col class="text-grey" cols="8">
                          {{ cursoData.estado_curso }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-autocomplete multiple v-model="cursoData.profesionales" :items="tiposProfesionales"
                      item-title="categoria" item-value="id" :label="t('Profesional')" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('profesional')"></v-autocomplete>
                    <v-autocomplete multiple v-model="cursoData.docentes" :items="posibles_docentes" item-title="email"
                      item-value="id" :label="t('Docentes')" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('docentes')"></v-autocomplete>
                      <v-autocomplete multiple v-model="cursoData.administradores" :items="posibles_administradores" item-title="email"
                      item-value="id" :label="t('Administradores')" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('administradores')"></v-autocomplete>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel :title="t('Acceso_regulado_por_email') + ' (' + t('alumnos') + ')'">
                  <v-expansion-panel-text>
                    <v-textarea v-model="emailsRaw"
                      label="Pega los emails de las personas a las que quieras conceder acceso (@vallhebron.cat)"
                      hint="Puedes copiar y pegar los emails seleccionándolos todos a la vez en una columna de Excel"
                      persistent-hint auto-grow :rows="3"></v-textarea>
                    <v-btn @click="updateCurso(true)" prepend-icon="mdi-content-save">
                      {{ t('Guardar') }} {{ t('emails') }}
                    </v-btn>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel :title="t('Características_avanzadas_opcionales')">
                  <v-expansion-panel-text>
                    <v-select multiple v-model="cursoData.rama" :items="tiposRamas" item-title="categoria"
                      item-value="id" :label="t('Áreas')" required append-icon="mdi-information"
                      @click:append="() => openHelpDialog('areas')"></v-select>
                    <v-text-field v-model="cursoData.fecha_inicio" :label="t('Fecha_de_inicio')"
                      type="date"></v-text-field>
                    <v-text-field v-model="cursoData.fecha_fin" :label="t('Fecha_de_fin')" type="date"></v-text-field>
                    <v-text-field v-model="cursoData.experiencia_minima" :label="t('Experiencia mínima')"
                      type="number"></v-text-field>
                    <v-text-field v-model="cursoData.experiencia_maxima" :label="t('Experiencia máxima')"
                      type="number"></v-text-field>
                    <v-text-field v-model="cursoData.experiencia_docente" :label="t('Experiencia docente')"
                      type="number"></v-text-field>
                    <v-text-field v-model="cursoData.afos" label="AFOS" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('afos')"></v-text-field>
                    <v-btn color="red darken-1" text @click="openDeleteConfirmDialog()">{{ t('Eliminar') }}</v-btn>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteConfirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ t('Estás_seguro') }}</v-card-title>
        <v-card-text>
          {{ t('Estás_seguro_que_quieres_eliminar') }} {{ t('la_actividad') }} "{{ curso.titulo }}"? {{
            t('Esta_acción_eliminará_todas') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDeleteConfirmDialog = false">{{ t('Cancelar') }}</v-btn>
          <v-btn color="red darken-1" text @click="confirmDeleteCurso">{{ t('Eliminar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddDialog" persistent>
      <v-card>
        <HelperCarregant v-if="loadingModuls" :missatge="t('Creando') + ' ' + t('contenido')" />
        <template v-if="!loadingModuls">
          <v-toolbar flat>
            <v-toolbar-title>{{ t('Crear') }} {{ t(AddForm.tipus) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="SubmitNew()" prepend-icon="mdi-content-save">
              {{ t('Guardar') }}
            </v-btn>
            <v-btn icon @click="showAddDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="AddForm.titulo" :label="t('Título')" outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="AddForm.descripcion" :label="t('Descripción')" outlined></v-textarea>
                </v-col>
              </v-row>
              <v-row>Els vídeos, imatges, PDFs i qüestionaris van vinculats a les lliçons. Si vols afegir-ne, ho podràs
                fer en
                un segon temps, editant les lliçons ja creades.</v-row>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="helpDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">{{ t('Ayuda') }}</v-card-title>
        <v-card-text>{{ helpText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="helpDialog = false">{{ t('Cerrar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { axios } from '../axios';
import CuestionarioCurso from './CuestionarioCurso.vue';
import ModuloLeccion from './ModuloLeccion.vue';
import LeccionContent from './LeccionContent.vue';
import HelperCarregant from './HelperCarregant.vue';

const { t } = useI18n();
const display = useDisplay();
const drawer = ref(true);
const store = useStore();
const route = useRoute();
const selectedOptionVista = ref(store.getters.selectedOptionVista);
const curso = computed(() => store.getters.curso);
const moduls = computed(() => store.getters.moduls);
const cursoData = ref({});
const tiposProfesionales = ref([]);
const tiposRamas = ref([]);
const emailsRaw = ref('');
const panel = ref([0, 1]);
const leccionActual = ref(null);
const moduloActual = ref(null);
const cursoId = ref(route.params.uid);
const moduloIndex = ref(null);
const leccionIndex = ref(null);
const selectedOption = ref('mirar');
const options = [
  { label: 'Mirar', value: 'mirar', icon: 'mdi-eye' },
  { label: 'Editar', value: 'editar', icon: 'mdi-pencil' }
];
const optionsVista = [
  { label: 'Vista_alumno', value: 'alumno', icon: 'mdi-account' },
  { label: 'Vista_docente', value: 'docente', icon: 'mdi-account-multiple-check' }
];
const showDialog = ref(false);
const showAddDialog = ref(false);
const showDeleteConfirmDialog = ref(false);
const AddForm = ref({
  curso: route.params.uid,
  modulo: null,
  tipus: 'modulos',
  tipo: null,
  titulo: '',
  descripcion: '',
});
const snackbarText = ref('');
const posibles_docentes = ref([]);
const posibles_administradores = ref([]);
const helpDialog = ref(false);
const helpText = ref('');
const loadingCurso = computed(() => store.getters.loadingCurso);
const loadingModuls = computed(() => store.getters.loadingModuls);

const hasNextLeccion = computed(() => {
  const isLastLeccionInModulo = leccionIndex.value === moduls.value.modulos[moduloIndex.value].lecciones.length - 1;
  const isLastModulo = moduloIndex.value === moduls.value.modulos.length - 1;
  return !isLastLeccionInModulo || !isLastModulo;
});

const hasPreviousLeccion = computed(() => {
  const isFirstLeccionInModulo = leccionIndex.value === -1;
  const isFirstModulo = moduloIndex.value === 0;
  return !isFirstLeccionInModulo || !isFirstModulo;
});

watch(emailsRaw, (newVal) => {
  processPastedEmails(newVal);
});

watch(selectedOption, (newVal, oldVal) => {
  if (oldVal === 'editar' && newVal === 'mirar') {
    store.dispatch('fetchCurso', cursoId.value);
  }
});

watch(selectedOptionVista, (newVal) => {
  store.dispatch('setSelectedOptionVista', newVal);
  moduloIndex.value = null;
  leccionIndex.value = null;
  leccionActual.value = null;
  store.dispatch('fetchModuls', cursoId.value);
});

const openHelpDialog = (field) => {
  helpDialog.value = true;
  switch (field) {
    case 'descripcion':
      helpText.value = 'Describe tu actividad con una frase atractiva.';
      break;
    case 'presentacion':
      helpText.value = 'Haz una presentación o introducción a tu actividad.';
      break;
    case 'imagen':
      helpText.value = 'Sube una imagen representativa de la actividad.';
      break;
    case 'profesional':
      helpText.value = '¿A qué profesionales va dirigida tu actividad? Marca todos los que apliquen.';
      break;
    case 'director':
      helpText.value = 'El director es la persona responsable del contenido de la actividad. El director puede editar el contenido de su actividad y su acceso por parte de otros usuarios.';
      break;
    case 'docentes':
      helpText.value = 'Puedes dar acceso docente a otros profesionales registrados que estén relacionados con la especialidad o categoría profesional de tu actividad. Los docentes tienen acceso a la guía docente de la actividad y pueden evaluar los alumnos.';
      break;
    case 'administradores':
      helpText.value = 'Puedes dar acceso de administrador a otros profesionales de tu organización. Los administradores tienen acceso de edición a todo el contenido de la actividad.';
      break;
    case 'areas':
      helpText.value = '¿En qué URLs tiene que estar disponible tu actividad? Por defecto selecciona vallhebron (vallhebron.simulacio.com). Si deseas que tu actividad esté disponible en alguna de las otras URLs (por ejemplo gine.simulacio.com), seleccionalas.';
      break;
    case 'afos':
      helpText.value = 'El código AFOS sirve para identificar tu actividad en el Centre de Simulació. Para que el control de asistencia con QR funcione correctamente es fundamental que el código coincida exactamente con el código introducido en el sistema RedCap del centro.';
      break;
    default:
      helpText.value = 'Información no disponible.';
  }
};

const selectModulo = (modulo, moduloIndexValue) => {
  moduloActual.value = modulo;
  leccionActual.value = null;
  moduloIndex.value = moduloIndexValue;
  leccionIndex.value = -1;
};

const selectLeccion = (leccion, moduloIndexValue, leccionIndexValue) => {
  moduloActual.value = null;
  leccionActual.value = leccion;
  moduloIndex.value = moduloIndexValue;
  leccionIndex.value = leccionIndexValue;
};

function getNextIndices(modulos, currentModuloIndex, currentLeccionIndex) {
  // Copiamos para no mutar parámetros directamente
  let mIndex = currentModuloIndex
  let lIndex = currentLeccionIndex

  if (lIndex === -1) {
    // Si estamos "viendo el módulo", el siguiente paso es la primera lección
    // ... siempre y cuando haya lecciones en este módulo
    if (modulos[mIndex].lecciones.length > 0) {
      lIndex = 0
    } else {
      // Si NO hay lecciones en este módulo, pasamos al siguiente módulo
      // (o paramos si estamos en el último)
      mIndex++
      if (mIndex >= modulos.length) {
        return { 
          moduloIndex: currentModuloIndex, 
          leccionIndex: currentLeccionIndex, 
          canMove: false 
        }
      }
      // En el nuevo módulo, empezamos con "ver módulo" (-1) o la primera lección
      lIndex = -1
    }
  } else {
    // Ya estamos en una lección
    lIndex++
    // Si sobrepasamos el número de lecciones, pasamos al siguiente módulo con lIndex = -1
    if (lIndex >= modulos[mIndex].lecciones.length) {
      mIndex++
      if (mIndex >= modulos.length) {
        // No hay siguiente
        return { 
          moduloIndex: currentModuloIndex, 
          leccionIndex: currentLeccionIndex, 
          canMove: false 
        }
      }
      // Volvemos a modo módulo del siguiente
      lIndex = -1
    }
  }

  return { moduloIndex: mIndex, leccionIndex: lIndex, canMove: true }
}

function getPreviousIndices(modulos, currentModuloIndex, currentLeccionIndex) {
  let mIndex = currentModuloIndex
  let lIndex = currentLeccionIndex

  if (lIndex === -1) {
    // Estamos viendo "solo módulo"
    // El paso anterior sería la última lección del módulo anterior (o su vista de módulo -1)
    mIndex--
    if (mIndex < 0) {
      return { 
        moduloIndex: currentModuloIndex, 
        leccionIndex: currentLeccionIndex, 
        canMove: false 
      }
    }
    // En el módulo anterior, si tiene lecciones, vamos a la última
    // o si no, nos quedamos en -1
    if (modulos[mIndex].lecciones.length > 0) {
      lIndex = modulos[mIndex].lecciones.length - 1
    } else {
      lIndex = -1
    }
  } else {
    // Estamos en una lección
    lIndex--
    // Si retrocedemos más allá de la lección 0, tocamos el modo "ver módulo"
    if (lIndex < 0) {
      // Volvemos a la "vista de módulo" del actual
      // (Si prefieres saltar directamente al módulo anterior, ajusta la lógica)
      lIndex = -1
    }
  }

  return { moduloIndex: mIndex, leccionIndex: lIndex, canMove: true }
}


const selectNextLeccion = () => {
  const { moduloIndex: newM, leccionIndex: newL, canMove } = getNextIndices(
    moduls.value.modulos,
    moduloIndex.value,
    leccionIndex.value
  );
  if (!canMove) return;

  moduloIndex.value = newM;
  leccionIndex.value = newL;

  // Si la lección es -1, estamos en "vista de módulo"
  if (newL === -1) {
    moduloActual.value = moduls.value.modulos[newM];
    leccionActual.value = null;
  } else {
    moduloActual.value = null;
    leccionActual.value = moduls.value.modulos[newM].lecciones[newL];
  }
};

const selectPreviousLeccion = () => {
  const { moduloIndex: newM, leccionIndex: newL, canMove } = getPreviousIndices(
    moduls.value.modulos,
    moduloIndex.value,
    leccionIndex.value
  );
  if (!canMove) return;

  moduloIndex.value = newM;
  leccionIndex.value = newL;

  // Si la lección es -1, estamos en "vista de módulo"
  if (newL === -1) {
    moduloActual.value = moduls.value.modulos[newM];
    leccionActual.value = null;
  } else {
    moduloActual.value = null;
    leccionActual.value = moduls.value.modulos[newM].lecciones[newL];
  }
};


const fetchProfesionales = async () => {
  try {
    const response = await axios.get('profesionales/');
    tiposProfesionales.value = response.data.sort((a, b) => a.categoria.localeCompare(b.categoria));
  } catch (error) {
    console.error(error);
  }
};

const fetchRamas = async () => {
  try {
    const response = await axios.get('ramas/');
    tiposRamas.value = response.data;
  } catch (error) {
    console.error(error);
  }
};


const openEditDialog = () => {
  getCursoData();
  fetchProfesionales();
  fetchPosiblesDocentes();
  fetchPosiblesAdministradores();
  fetchRamas();
  showDialog.value = true;
};

const getCursoData = () => {
  axios.get(`cursos/${curso.value.id}/`)
    .then(response => {
      cursoData.value = response.data;
      cursoData.value.imagen_thub = response.data.imagen;
      cursoData.value.imagen = null;
      emailsRaw.value = cursoData.value.admitidos.join('\n');
    })
    .catch(error => {
      console.error(error);
    });
};

const processPastedEmails = (emailsString) => {
  const emails = emailsString.split(/\r?\n/);
  cursoData.value.admitidos = emails.map(email => email.trim()).filter(email => email);
};

const handleFileUpload = (event) => {
  if (event.target.files && event.target.files.length > 0) {
    cursoData.value.imagen = event.target.files[0];
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cursoData.value.imagen_thub = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  } else {
    cursoData.value.imagen = null;
  }
};

const updateCurso = (emails) => {
  if (cursoData.value.rama.length === 0) {
    snackbarText.value = 'Debes seleccionar al menos una área en la sección de visibilidad';
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
    return;
  }
  const formData = new FormData();
  Object.keys(cursoData.value).forEach(key => {
    const value = cursoData.value[key];
    if (value !== null && value !== undefined) {
      if (key === 'imagen' && value instanceof File) {
        formData.append(key, value);
      } else if (key === 'admitidos' && typeof value === 'object') {
        if (emails === true) {
          formData.append(key, JSON.stringify(value));
        }
      } else if (Array.isArray(value)) {
        value.forEach(item => {
          formData.append(key, item);
        });
      } else if (key !== 'imagen_thub') { // Solo omitir imagen_thub
        formData.append(key, value);
      }
    }
  });

  axios.put(`cursos/${curso.value.id}/`, formData)
    .then(response => {
      showDialog.value = false;
      snackbarText.value = response.data.titulo + ' ' + 'fue actualizado con éxito';
      store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
      store.dispatch('fetchCurso', cursoId.value);
    })
    .catch(error => {
      if (error.response) {
        if (error.response.data.non_field_errors) {
          snackbarText.value = 'Error: ' + error.response.data.non_field_errors.join(', ');
        } else if (error.response.data.detail) {
          snackbarText.value = 'Error: ' + error.response.data.detail;
        } else {
          snackbarText.value = '';
          Object.keys(error.response.data).forEach(key => {
            snackbarText.value += `${key}: ${error.response.data[key].join(', ')}; `;
          });
        }
      }
      store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
    });
};

const openDeleteConfirmDialog = () => {
  showDeleteConfirmDialog.value = true;
};

const confirmDeleteCurso = () => {
  axios.delete(`cursos/${curso.value.id}`)
    .then(() => {
      showDialog.value = false;
      showDeleteConfirmDialog.value = false;
      this.$router.push('/inspira');
      snackbarText.value = t("se_ha_eliminado_con_éxito");
      store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
    })
    .catch(error => {
      store.dispatch('triggerSnackbar', { message: error, color: 'error' });
    });
};

const openAddDialog = (tipus, moduloId = null) => {
  AddForm.value.tipus = tipus;
  AddForm.value.titulo = '';
  AddForm.value.descripcion = '';
  if (tipus === 'lecciones') {
    AddForm.value.modulo = moduloId;
  } else {
    AddForm.value.modulo = null;
    AddForm.value.tipo = selectedOptionVista.value;
  }
  showAddDialog.value = true;
};

const SubmitNew = async () => {
  const formData = new FormData();
  Object.keys(AddForm.value).forEach(key => {
    const value = AddForm.value[key];
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });
  await axios.post(`${AddForm.value.tipus}/`, formData).then(response => {
    snackbarText.value = response.data.titulo + ' ' + 'fue creado con éxito';
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
    showAddDialog.value = false;
    store.dispatch('fetchModuls', cursoId.value);
  }).catch(error => {
    if (error.response) {
      if (error.response.data.non_field_errors) {
        snackbarText.value = 'Error: ' + error.response.data.non_field_errors.join(', ');
      } else if (error.response && error.response.data && typeof error.response.data === 'object') {
        const errors = error.response.data;
        let errorMessage = '';
        Object.keys(errors).forEach(key => {
          errorMessage += `${key}: ${errors[key].join(' ')}\n`;
        });
        snackbarText.value = errorMessage;
      }
    }
    snackbarText.value = error;
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
  });
};

const handleNuevoCuestionario = ({ data }) => {
  moduls.value.modulos[moduloIndex.value].lecciones[leccionIndex.value].cuestionario = data;
  leccionActual.value.cuestionario = data;
};

const handleEliminarCuestionario = () => {
  moduls.value.modulos[moduloIndex.value].lecciones[leccionIndex.value].cuestionario = null;
  leccionActual.value.cuestionario = null;
};

const handleUpdateModulo = ({ index, data }) => {
  moduls.value.modulos.splice(index, 1, data);
};

const handleDeleteModulo = (index) => {
  moduloActual.value = null;
  moduls.value.modulos.splice(index, 1);
};

const handleUpdateLeccion = ({ index, data }) => {
  moduls.value.modulos[moduloIndex.value].lecciones.splice(index, 1, data);
};

const handleDeleteLeccion = (index) => {
  leccionActual.value = null;
  moduls.value.modulos[moduloIndex.value].lecciones.splice(index, 1);
};

const handleReorder = (payload) => {
  const { type, index } = payload;
  if (type === 'up' && index > 0) {
    const temp = moduls.value.modulos[index];
    moduls.value.modulos[index] = moduls.value.modulos[index - 1];
    moduls.value.modulos[index - 1] = temp;
  } else if (type === 'down' && index < moduls.value.modulos.length - 1) {
    const temp = moduls.value.modulos[index];
    moduls.value.modulos[index] = moduls.value.modulos[index + 1];
    moduls.value.modulos[index + 1] = temp;
  }
};

const handleReorderL = (payload) => {
  const { type, index } = payload;
  if (type === 'up' && index > 0) {
    const temp = moduls.value.modulos[moduloIndex.value].lecciones[index];
    moduls.value.modulos[moduloIndex.value].lecciones[index] = moduls.value.modulos[moduloIndex.value].lecciones[index - 1];
    moduls.value.modulos[moduloIndex.value].lecciones[index - 1] = temp;
  } else if (type === 'down' && index < moduls.value.modulos[moduloIndex.value].lecciones.length - 1) {
    const temp = moduls.value.modulos[moduloIndex.value].lecciones[index];
    moduls.value.modulos[moduloIndex.value].lecciones[index] = moduls.value.modulos[moduloIndex.value].lecciones[index + 1];
    moduls.value.modulos[moduloIndex.value].lecciones[index + 1] = temp;
  }
};

const fetchPosiblesDocentes = async () => {
  try {
    const response = await axios.get(`curso/${cursoId.value}/posibles_docentes/`);
    posibles_docentes.value = response.data;
  } catch (error) {
    console.error("Error al obtener los posibles docentes:", error);
  }
};

const fetchPosiblesAdministradores = async () => {
  try {
    const response = await axios.get(`curso/${cursoId.value}/posibles_administradores/`);
    posibles_administradores.value = response.data;
  } catch (error) {
    console.error("Error al obtener los posibles administradores:", error);
  }
};

function fetchModuls() {
  store.dispatch('fetchModuls', cursoId.value);
}

onMounted(() => {
  store.dispatch('fetchCurso', cursoId.value);
  store.dispatch('fetchModuls', cursoId.value);
});
</script>